.spot-tooltip
  $hover-animation-distance: 10px

  position: relative
  display: inline-flex

  &--body
    @include spot-z-index("tooltip", 1)
    pointer-events: none
    // This keeps the element rendered in the DOM and thus readable by screen readers
    opacity: 0.001
    width: 80%

    transition-property: transform, opacity
    transition-timing-function: ease
    transition-duration: 0.2s
    transition-delay: 0.2s

    position: absolute
    height: auto

    box-shadow: $spot-shadow-light-low
    color: $spot-color-basic-gray-1
    background: $spot-color-main-light
    border-radius: 5px

    display: flex
    flex-direction: column

    &_left-top
      right: 100%

    &_left-center
      right: 100%
      top: 50%
      transform: translateY(-50%)

    &_left-bottom
      right: 100%

    &_bottom-left
      top: 100%
      left: $spot-spacing-1
      transform: translateY(-$hover-animation-distance)

    &_bottom-center
      top: 100%
      left: 50%
      transform: translateX(-50%) translateY(-$hover-animation-distance)

    &_bottom-right
      top: 100%
      right: $spot-spacing-1
      transform: translateY(-$hover-animation-distance)

    &_right-top
      left: 100%

    &_right-center
      left: 100%
      top: 50%
      transform: translateY(-50%)

    &_right-bottom
      left: 100%

    &_top-left
      bottom: 100%
      left: $spot-spacing-1
      transform: translateY($hover-animation-distance)

    &_top-center
      bottom: 100%
      left: 50%
      transform: translateX(-50%) translateY($hover-animation-distance)

    &_top-right
      bottom: 100%
      right: $spot-spacing-1
      transform: translateY($hover-animation-distance)

    .spot-body-small
      &:first-child
        margin-top: $spot-spacing-0-5

      &:last-child
        margin-bottom: $spot-spacing-0-5

  &:hover &--body
    opacity: 1

  &:hover &--body_top-left,
  &:hover &--body_top-right,
  &:hover &--body_bottom-left,
  &:hover &--body_bottom-right
    transform: translateY(0)

  &:hover &--body_top-center,
  &:hover &--body_bottom-center
      transform: translateX(-50%) translateY(0)

  &_dark &--body
    background: $spot-color-basic-gray-1
    color: $spot-color-basic-white
